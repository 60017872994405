<template lang="pug">
  #rolePlay
    Section(title="Role Play", :subtitle="subtitle", :time="time")
      v-row.flex-column-reverse.flex-md-row
        v-col(cols="12" md="6")
          v-card(flat, v-for="q, idx in lesson.rolePlay", :key="q" :color="'grey lighten-' + (idx+4 < 5 ? idx + 4 : 5)").pa-5.mb-4
            .mb-3.text--disabled.d-flex.align-end.justify-center.justify-md-start
              .label Option
              .number.text-h4.ml-2 {{idx+1}}
            .title {{q}}
        v-col(cols="12" md="6")
          .px-12
            v-img(src="@/assets/images/rolePlay.svg" cover)
</template>

<script>
import { get } from "vuex-pathify";
import Section from "@/components/Lesson/Section.vue";
export default {
  name: "RolePlay",
  components: { Section },
  props: {
    subtitle: { type: String, default: null },
    time: { type: String, default: null },
  },
  computed: {
    ...get({ lesson: "Lessons/lesson" }),
  },
};
</script>
