<template lang="pug">
    .lesson
        Title
        v-container.px-md-0
          .no-show-available(v-if="!lesson || !lesson.show || loading")
              v-card(outlined).pa-5.mb-12
                v-row
                  v-col(cols="12" md="4")
                    v-skeleton-loader(type="image").mb-12
                  v-col(cols="12" md="8")
                    v-skeleton-loader(type="card").mb-12
              
              v-skeleton-loader(type="article").mb-12
              v-skeleton-loader(type="card").mb-12

          .show-is-available(v-else)
              CreateStudentVersion(v-if="!$route.params.requiresSubscription || userData && userData.subscriptionIsActive").mb-4
              v-btn(block tile v-if="isDev", depressed color="primary", href="#", target="_blank", @click.prevent="handleReInit") Re-init Lesson plan (no tts)

              v-row.flex-column-reverse.flex-md-row
                  v-col(cols="12")
                      Summary
                  v-col(cols="12" md="7" lg="8")
                      GettingStarted(:lesson="lesson")
                  v-col(cols="12" md="5" lg="4")
                      NavDrawer
              
              .sections.mt-5
                  WarmUp(subtitle="Warm up by asking your students to consider some open-ended general questions:" :time="round5(lesson.warmup.length * 1) + ' ~ ' + round5(lesson.warmup.length * 4) + ' minutes'")
                  
                  KeyExpressions(v-if="lesson.keyExpressions", :subtitle="`Review these ${lesson.keyExpressions.length} key expressions from the transcript:`", :time="'Approx ' + round5(lesson.keyExpressions.length) + ' ~ ' +  round5(lesson.keyExpressions.length * 2) + ' minutes'")
                  
                  ImagePrompt(v-if="lesson.imagePrompts && lesson.imagePrompts.length > 0", subtitle="Tell your students that you will show them an image and ask them a question. They will have a limited amount of time to respond.", time="5 minutes")
                  
                  CheckUnderstanding(v-if="lesson.checkUnderstanding", subtitle="Let's make sure your students watched the episode and understood what was going on." :time="round5(lesson.checkUnderstanding.length * 2) + ' ~ ' + round5(lesson.checkUnderstanding.length * 3) + ' minutes'")
                  
                  Discussion(subtitle="Lead students in an open-ended discussion of the scenes, themes, plot and characters in the episode" :time="round5(lesson.discussion.length * 4) + ' ~ ' + round5(lesson.discussion.length * 7) + ' minutes'")
                  
                  Shadowing(subtitle="Shadowing is a repetition technique for more natural pronunciation." :time="round5(lesson.shadowing[0].text.length / 2) + ' ~ ' + round5(lesson.shadowing[0].text.length) + ' minutes'")
                  
                  RolePlay(subtitle="Students take the roles of characters and act out the scenes by creating their own version of events" time="10 ~ 20 minutes")
                  
                  Cloze(v-if="lesson.cloze && lesson.cloze.length > 0", subtitle="A cloze exercise involves replacing the words in a text, to demonstrate mastery of the content." time="20 ~ 30 minutes")
                  
                  TensePractice(v-if="lesson.tensePractice && lesson.tensePractice.past && lesson.tensePractice.past.length > 0", subtitle="Decide on a tense and ask your student to click on it. They will have to fill in the plot summary in the tense of your choice.", time="10 ~ 20 minutes")

                  ArticlesPractice(subtitle="Ask your student to click on any incorrect articles, or to click in the space before a word that needs an article.", note="Some articles may be correct already, but clicking on them will show an alternate option.", time="5 ~ 30 minutes")

                  SubscriptionBottomOverlay(v-if="lesson.episode === 1 || lesson.episode === 2", inline)
                  
                  Hangman(title="Guessing Game", subtitle="Ask your student to type letters and try to guess the expression", note="Some expresssions might have punctuation, like question marks (?), exclamation marks (!), hyphens (-), or single quotation marks (').", :keyExpressions="lesson.keyExpressions", time="5 ~ 10 minutes")

                  WantMore
</template>

<script>
import CreateStudentVersion from "@/components/Lesson/CreateStudentVersion.vue";
import NavDrawer from "@/components/Lesson/NavDrawer.vue";
import Summary from "@/components/Lesson/Sections/Summary.vue";

import Title from "@/components/Lesson/Sections/Title.vue";
import GettingStarted from "@/components/Lesson/Sections/GettingStarted.vue";
import WarmUp from "@/components/Lesson/Sections/WarmUp.vue";
import KeyExpressions from "@/components/Lesson/Sections/KeyExpressions/KeyExpressions.vue";
import CheckUnderstanding from "@/components/Lesson/Sections/CheckUnderstanding.vue";
import Discussion from "@/components/Lesson/Sections/Discussion.vue";
import Shadowing from "@/components/Lesson/Sections/Shadowing/Shadowing.vue";
import RolePlay from "@/components/Lesson/Sections/RolePlay.vue";
import Cloze from "@/components/Lesson/Sections/Cloze.vue";
import ImagePrompt from "@/components/Lesson/Sections/ImagePrompt/ImagePrompt.vue";
import ArticlesPractice from "@/components/Lesson/Sections/ArticlesPractice/ArticlesPractice.vue";
import TensePractice from "@/components/Lesson/Sections/TensePractice/TensePractice.vue";

import SubscriptionBottomOverlay from "@/components/Billing/SubscriptionBottomOverlay.vue";

import Hangman from "@/components/Games/Hangman/Hangman.vue";
import WantMore from "@/components/WantMore.vue";

import { get, sync, call } from "vuex-pathify";
export default {
  name: "Lesson",
  components: {
    CreateStudentVersion,
    NavDrawer,
    Summary,
    Title,
    GettingStarted,
    WarmUp,
    KeyExpressions,
    CheckUnderstanding,
    Discussion,
    Shadowing,
    RolePlay,
    Cloze,
    SubscriptionBottomOverlay,
    ImagePrompt,
    ArticlesPractice,
    Hangman,
    WantMore,
    TensePractice,
  },
  data() {
    return { error: null, loading: false };
  },
  created() {
    this.init();
  },
  mounted() {},
  computed: {
    ...get({
      lesson: "Lessons/lesson",
      userData: "User/userData",
      isDev: "isDev",
      functionsHostname: "functionsHostname",
    }),
    ...sync({ isTeacher: "Lessons/isTeacher" }),
  },
  methods: {
    ...call({
      clearLesson: "Lessons/clearLesson",
      updateLesson: "Lessons/updateLesson",
    }),
    async init() {
      try {
        this.isTeacher = true;
        await this.clearLesson();
        await this.updateLesson({ id: this.$route.params.id });
        document.getElementById("main").scrollIntoView();
      } catch (err) {
        console.error(err);
        this.error = err;
      }
    },
    handleReInit() {
      // this.loading = true;
      const href = `${
        this.functionsHostname
      }/initLesson?lessonId=${this.lesson.show.toLowerCase()}_s0${
        this.lesson.season
      }_e0${this.lesson.episode}`;

      fetch(href)
        .then(() => this.init())
        .catch((err) => console.error(err));
    },
    round5(x) {
      return Math.ceil(x / 5) * 5;
    },
  },
};
</script>

<style lang="scss">
ol,
ul {
  margin-top: 1rem;
}
li {
  margin-bottom: 1rem;
}
</style>
