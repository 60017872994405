<template lang="pug">
  .expressions(v-if="lesson.keyExpressions && lesson.keyExpressions.length")
    .intro.mb-2 Teach {{lesson.keyExpressions.length}} key expressions, such as:
    .sample-expressions(v-for="expression in (randomExpressions[lesson.id] || getRandomExpressions(lesson.id, lesson.keyExpressions))", :key="expression")
      v-row(align="start" justify="center" dense)
        v-col(cols="2")
          v-icon.ml-2(color="grey") mdi-chevron-right
        v-col(cols="10")
          span {{expression}}

</template>

<script>
import { randomFromArray } from "@/helpers.js";
export default {
  name: "LessonTitleImageOverlay",
  props: {
    lesson: { type: Object, default: null },
  },
  data() {
    return {
      randomExpressions: {},
    };
  },
  computed: {},
  methods: {
    getRandomExpressions(lessonId, keyExpressions) {
      if (!keyExpressions || keyExpressions.length < 3) return keyExpressions;

      if (this.randomExpressions[lessonId])
        return this.randomExpressions[lessonId];

      const randomExpressions = randomFromArray(
        keyExpressions.filter(
          (expression) => expression.expression.length < 19 // only get shorter length expressions
        ),
        3
      ).map((itm) => itm.expression);

      this.randomExpressions[lessonId] = randomExpressions;
      return randomExpressions;
    },
  },
};
</script>

<style lang="scss" scoped></style>
