<template lang="pug">
    .p-relative
      v-overlay(absolute :color="lesson.active === false ? 'warning darken-4' : 'primary lighten-1'", opacity="0.8")
        .text-center
          .title.mt-8 {{lesson.show}}
          h1 S {{lesson.season}} / E {{lesson.episode}}
          .coming-soon.text-center(v-if="lesson.active === false") Coming soon!
      
      v-img(eager lazy-src="/posters/placeholder.jpg", :src="`/posters/${lesson.show.toLowerCase().split(' ').join('_')}.jpg`" cover min-height="215px" max-height="215px")

</template>

<script>
export default {
  name: "LessonTitleImageOverlay",
  props: {
    lesson: { type: Object, default: null },
  },
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
