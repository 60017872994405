<template lang="pug">
    Section(title="Cloze Exercise", anchor="cloze", :subtitle="subtitle", :time="time")
      v-card(v-if="isTeacher", flat color="grey lighten-4").pa-5.mb-6
        h4.mb-3 How to use this section
        p.mb-0 As the teacher, you can see 3 kinds of special highlights in this section:
        .example.my-6
            v-tooltip(top)
              template(v-slot:activator="{ on, attrs }")
                span.font-weight-bold
                  mark(v-on="on") Terms with hints
              span This is where a hint will popup
            span.ml-2 your student can hover their mouse over these terms to see a definition or hint;
          
        .cloze.my-6
          mark.incorrect.mr-2 Terms to replace
          span your student WILL see this term and should think of one of the key phrases from this lesson, with which to replace it;
        .cloze 
          mark.correct.mr-2 Correct Key Phrases 
          span your student will NOT see this term, but you can. The student should guess this term as a replacement for the red one;

      v-row.flex-column-reverse.flex-md-row
        v-col(cols="12" md="8")

          .line.d-flex.align-start(v-for="q, clozeId in lesson.cloze", :key="q")
            .chip.grey.lighten-4.px-3.mr-4.grey--text.text-center
              small {{clozeId + 1}}
            .question.mb-6
              span.elem(v-for="line, idx in lines(q)", :key="idx")
                  span(v-if="line.type==='span'") {{line.value}}
                  strong(v-if="line.type==='strong'") {{line.value}}
                  em(v-else-if="line.type==='em'") {{line.value}}
                  s(v-else-if="line.type==='s'") {{line.value}}
                  v-tooltip(v-else-if="line.type==='tooltip'" top)
                    template(v-slot:activator="{ on, attrs }")
                      span.font-weight-bold
                        mark(v-on="on") {{line.value.split('|')[0]}}
                    span {{line.value.split('|')[1]}}
                  span.cloze(v-if="line.type==='cloze'")
                    mark.incorrect(:id="getId(clozeId, idx)", :class="{active:$route.hash.includes(getId(clozeId, idx))}", @click="navToSectionTitle({to:'Key Expressions',from:'#' + getId(clozeId, idx)})")
                        v-icon.mr-1(small color="pink") mdi-alert-outline
                        | {{line.value.split("|")[0]}}
                    mark.correct(v-if="isTeacher") {{line.value.split("|")[1]}}

        v-col(cols="12" md="4")
          .px-12
            v-img(src="@/assets/images/cloze.svg" cover)
</template>

<script>
import { get, call } from "vuex-pathify";

import Section from "@/components/Lesson/Section.vue";

import { tokenize } from "simple-html-tokenizer";
import MarkdownIt from "markdown-it";

import markdownItRegex from "markdown-it-regex";
const mdi = MarkdownIt();

mdi.use(markdownItRegex, {
  name: "tooltips",
  regex: /\^{(.*?)}/, // format: ${words in sentence|words that hover in tooltip}
  replace: (match) => {
    return `<tooltip>${match}</tooltip>`;
  },
});

mdi.use(markdownItRegex, {
  name: "cloze",
  regex: /%{(.*?)}/, // format: %{term 1|alternate term}
  replace: (match) => {
    return `<cloze>${match}</cloze>`;
  },
});

export default {
  name: "Cloze",
  components: { Section },
  props: {
    subtitle: { type: String, default: null },
    time: { type: String, default: null },
  },
  watch: {
    $route(newVal) {
      if (newVal.hash.includes("cloze-")) {
        const targetId = newVal.hash.split("#").join("");
        setTimeout(() => {
          document.getElementById(targetId).scrollIntoView();
        }, 1000);
      }
    },
  },
  created() {},
  computed: {
    ...get({ lesson: "Lessons/lesson", isTeacher: "Lessons/isTeacher" }),
  },
  methods: {
    ...call({ navToSectionTitle: "Nav/navToSectionTitle" }),
    getId(clozeId, idx) {
      return "cloze-incorrect-" + clozeId + "-" + idx;
    },
    lines(str) {
      const arr = this.tokenizeHtml(this.mdToHtml(str));

      let flat = [];
      for (let i = 0; i < arr.length; i++) {
        const thisElem = arr[i];

        const nextElem = arr[i + 1];
        if (thisElem.type === "EndTag") {
          // skip
        } else if (thisElem.type === "Chars") {
          flat.push({ type: "span", value: thisElem.chars });
        } else if (thisElem.type === "StartTag") {
          flat.push({ type: thisElem.tagName, value: nextElem.chars });
          i++;
        }
      }

      return flat;
    },
    mdToHtml(str) {
      return mdi.renderInline(str);
    },
    tokenizeHtml(html) {
      return tokenize(html);
    },
  },
};
</script>

<style lang="scss" scoped>
.question {
  line-height: 2;
}
mark {
  background: rgb(253, 253, 230);
  cursor: default;
  font-weight: bold;
}

.cloze {
  mark {
    padding: 0.2rem 0.5rem;
    display: inline;
    position: relative;
    font-weight: bold;
  }
  mark.incorrect {
    background: rgb(255, 233, 231);
    color: hotpink;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;

    &.active {
      // background: hotpink;
      // color: white;
      border: 2px solid hotpink;
      box-shadow: 5px 5px 10px yellow;
    }
  }

  mark.correct {
    background: #beeebe;
    color: forestgreen;
    text-shadow: 1px 1px white;
    // border-bottom: 2px dotted transparent;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}
</style>
