<template lang="pug">
  #dictation
    Section(:title="`Listening Game ${iteration}`", :subtitle="subtitle", :time="time")
        template(v-if="finished")
                v-card(color="success" flat).white--text.pa-6.text-center.mb-12
                    v-icon(x-large color="white") mdi-check-circle
                    h1.mb-3 Nice job!
                    v-btn(depressed @click="init") Play again?

        template(v-else)
            v-row(align="center")
                v-col(cols="12" md="6",lg="4", v-for="expression, idx in shuffledExpressionsToShow", :key="expression.id").mb-2
                    .question.d-flex.align-center
                        Audio(:filename="`${expression.id}_meaning.mp3`" :color="shuffledExpressionsToShow[idx].expression === answers[idx] ? 'success' : null")
                        .answers.ml-3
                            v-select(:dense="$vuetify.breakpoint.mdAndDown" v-if="shuffledExpressionsToShow[idx].expression !== answers[idx]", :disabled="answers[idx] === expression.expression" outlined hide-details label="Select the expression", :items="possibleAnswers", @change="shuffledExpressionsToShow[idx].expression === $event  ? $set(answers, idx, $event) : null")
                            .success--text(v-else)
                              |  {{expression.expression}}
                              v-chip(color="success").font-weight-bold.ml-2 Nice!

            .reset.text-center.mt-6
                a(href="#" @click.prevent='init').font-weight-bold.text-decoration-none.pink--text You're on game {{iteration}}. Start a new one (game {{iteration + 1}})?
</template>

<script>
import { get } from "vuex-pathify";
import Section from "@/components/Lesson/Section.vue";

import Audio from "@/components/Audio.vue";
import { randomFromArray } from "@/helpers.js";

export default {
  name: "DictationGame",
  components: { Section, Audio },
  props: {
    subtitle: { type: String, default: null },
    time: { type: String, default: null },
  },
  watch: {
    keyExpressions(newVal) {
      this.expressions = newVal;
    },
  },
  data() {
    return {
      iteration: 0,
      expressions: [],
      maxExpressionsForGame: 6,
      answers: {},
      numExtrasExpressionsAsFiller: 4,
    };
  },
  created() {
    this.maxExpressionsForGame = this.$vuetify.breakpoint.mdAndUp ? 9 : 6;
    this.init();
  },
  computed: {
    ...get({ lesson: "Lessons/lesson" }),
    finished() {
      return Object.values(this.answers).length === this.maxExpressionsForGame;
    },
    shuffledExpressions() {
      // Step 1: shuffle key expressions
      // Step 2: slice out n of them for cards
      // Step 3: create double that number of cards (some with expressions, some with meanings)
      // Step 4: shuffle for the game board

      const shuffled = (array, seed) => {
        function random(seed) {
          var x = Math.sin(seed++) * 10000;
          return x - Math.floor(x);
        }

        var m = array.length,
          t,
          i;

        // While there remain elements to shuffle…
        while (m) {
          // Pick a remaining element…
          i = Math.floor(random(seed) * m--); // <-- MODIFIED LINE

          // And swap it with the current element.
          t = array[m];
          array[m] = array[i];
          array[i] = t;
          ++seed; // <-- ADDED LINE
        }

        return array;
      };

      const expressions = shuffled(this.expressions, this.iteration);

      const subsetOfExpressions = expressions.slice(
        0,
        this.maxExpressionsForGame + this.numExtrasExpressionsAsFiller
      );

      return shuffled(subsetOfExpressions, this.iteration);
    },
    shuffledExpressionsToShow() {
      return this.shuffledExpressions.slice(
        0,
        this.shuffledExpressions.length - this.numExtrasExpressionsAsFiller
      );
    },
    possibleAnswers() {
      if (!this.shuffledExpressions || this.shuffledExpressions.length < 1)
        return [];
      const beforeFilter = this.shuffledExpressions.map(
        (expression) => expression.expression
      );

      const afterFilter = beforeFilter.filter(
        (expression) => JSON.stringify(this.answers).indexOf(expression) < 0
      );

      return randomFromArray(afterFilter, 1000);
    },
  },
  methods: {
    init() {
      this.iteration++;
      this.answers = {};
      this.expressions = JSON.parse(
        JSON.stringify(this.lesson.keyExpressions)
      ).map((keyExpression, idx) => {
        return { ...keyExpression, id: idx };
      });
    },
  },
};
</script>
