<template lang="pug">
        v-container.px-md-0(fluid)
          Section(color="grey lighten-5", title="TV Episode lesson plans", :subtitle="null" :time="`Hundreds of hours of ready-made ${$vuetify.breakpoint.smAndDown ? 'English lesson ' : ''}content`")
            v-row(v-if="!filteredLessons || filteredLessons.length < 1")
              v-col(cols="12" md="4" v-for="n in 24", :key="'a' + n")
                v-skeleton-loader(type="card")

            v-row(v-else)
              v-col(cols="12")
                LessonsFilter
                
              v-col(cols="12")
                v-row.season(dense v-for="season in seasons", :key="'season-' + season")
                  v-col(cols="12" v-if="seasons && seasons.length > 1")
                    .ml-2.text--disabled
                      v-icon.text--disabled.mr-2 mdi-television-play
                      | Season {{season}}
                  v-col(cols="12" md="4" v-for="lesson, idx in filteredLessons", :key="lesson.id + idx", v-if="lesson.season === season")
                    v-card.p-relative.white.pa-0.ma-2(flat lazy-src="@/assets/logo_white_bg.png" :hover="lesson.active !== false" :to="lesson.active !== false ? `/lesson/${lesson.id}` : null" :min-height="lesson.active !== false && $vuetify.breakpoint.mdAndUp ? '430px' : null")
                      .d-flex.flex-column
                        LessonChips(:lesson="lesson", :isFreeEpisode="isFreeEpisode(lesson.episode)")
                        
                        LessonTitleImageOverlay(:lesson="lesson")
                        LessonNote(:lesson="lesson", :backgroundColor="calcBackgroundColor(lesson)", :foregroundColor="calcForegroundColor(lesson)")
                        
                        .title-area.pa-4(v-if="lesson.active !== false")
                            LessonKeyExpressionsSample(:lesson="lesson")
                            
                            v-btn(:to="`/lesson/${lesson.id}`" :color="calcBackgroundColor(lesson)" block large depressed).mt-4
                              span(:style="{color: calcForegroundColor(lesson)}") View Lesson
                              v-icon.ml-2(:color="calcForegroundColor(lesson)") mdi-play-circle
</template>

<script>
import Section from "@/components/Lesson/Section.vue";
import LessonsFilter from "@/components/Lessons/Filter.vue";
import LessonChips from "@/components/Lessons/Chips.vue";
import LessonTitleImageOverlay from "@/components/Lessons/LessonTitleImageOverlay.vue";
import LessonKeyExpressionsSample from "@/components/Lessons/LessonKeyExpressionsSample.vue";
import LessonNote from "@/components/Lessons/LessonNote.vue";
import { get, sync, call } from "vuex-pathify";

export default {
  name: "Lessons",
  components: {
    Section,
    LessonsFilter,
    LessonChips,
    LessonTitleImageOverlay,
    LessonKeyExpressionsSample,
    LessonNote,
  },
  data() {
    return {};
  },
  created() {
    this.preloadPosters();
    this.updateLessons();
    this.isTeacher = true;

    if (this.$route.params.id) {
      this.lessonsFilter = this.$route.params.id
        .toLowerCase()
        .split("_")
        .join(" ");
    }
  },
  mounted() {
    if (!this.userData || !this.userData.subscriptionIsActive)
      this.subscriptionNeeded = true;
  },
  computed: {
    ...get({
      freeEpisodeNums: "Lessons/freeEpisodeNums",
      filteredLessons: "Lessons/filteredLessons",
      userData: "User/userData",
      shows: "Lessons/shows",
    }),
    ...sync({
      isTeacher: "Lessons/isTeacher",
      lessonsFilter: "Lessons/lessonsFilter",
      subscriptionNeeded: "Nav/dialogs@subscriptionNeeded",
    }),
    seasons() {
      return [...new Set(this.filteredLessons.map((lesson) => lesson.season))];
    },
  },
  methods: {
    ...call({ updateLessons: "Lessons/updateLessons" }),
    preloadPosters() {
      const images = this.shows.map(
        (show) =>
          `/posters/${show.title.toLowerCase().split(" ").join("_")}.jpg`
      );

      const loadImages = (images) => {
        return new Promise((resolve, reject) => {
          (function loadEach(images, index) {
            if (index < images.length) {
              let img = new Image();
              img.src = images[index];
              images[index] = img;
              images[index].onload = function () {
                loadEach(images, ++index);
              };
              images[index].onerror = (err) => reject(err);
            } else {
              resolve(images);
            }
          })(images, 0);
        });
      };

      loadImages(images)
        .then(() => {
          window.debug("done preloading poster images", images);
        })
        .catch((err) => console.error("poster image preload error:", err));
    },

    calcForegroundColor() {
      return "white";
    },
    calcBackgroundColor(lesson) {
      if (this.isFreeEpisode(lesson.episode)) {
        return this.$vuetify.theme.themes.light.success;
      } else {
        return this.userData && this.userData.subscriptionIsActive
          ? this.$vuetify.theme.themes.light.accent
          : "#E91E63";
      }
    },
    isFreeEpisode(num) {
      return this.freeEpisodeNums.includes(num);
    },
  },
};
</script>

<style lang="scss" scoped></style>
