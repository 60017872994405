import { render, staticRenderFns } from "./CreateStudentVersion.vue?vue&type=template&id=3cf1a9b7&scoped=true&lang=pug&"
import script from "./CreateStudentVersion.vue?vue&type=script&lang=js&"
export * from "./CreateStudentVersion.vue?vue&type=script&lang=js&"
import style0 from "./CreateStudentVersion.vue?vue&type=style&index=0&id=3cf1a9b7&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3cf1a9b7",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCard,VCol,VExpandTransition,VFadeTransition,VIcon,VImg,VRow})
