<template lang="pug">
        v-container.px-md-0.pt-md-0.mx-md-0(fluid)
          .no-show-available(v-if="loading")
              v-card(outlined).pa-5.mb-12
                v-row
                  v-col(cols="12")
                    h1.text--disabled Loading student version of this lesson...
                  v-col(cols="12" md="4")
                    v-skeleton-loader(type="image").mb-12
                  v-col(cols="12" md="8")
                    v-skeleton-loader(type="card").mb-12
                
                v-skeleton-loader(type="article").mb-12
                v-skeleton-loader(type="card").mb-12

          .not-found(v-if="error && !loading",)
            v-card.pa-5
              v-alert(color="red" outlined border="left" icon="mdi-alert", max-width="800px").my-12.mx-auto
                h1 Lesson link has expired
                v-divider.my-3
                p Sorry, it looks like this lesson link has expired and is no longer available.
                p If your teacher still has a subscription to SeriCity Pro, you can ask them to generate a new lesson link for you.
          
          .show-is-available(v-if="lesson && lesson.show")
              Title
              v-card.accent.lighten-2.pa-5(flat tile)
                  h2.mb-0.text-center Student Version


              .sections.mt-5
                  v-row.flex-column-reverse.flex-md-row
                    v-col(cols="12" md="7" lg="8")
                      WarmUp(subtitle="Your teacher will ask you these warm-up questions:")
                    v-col(cols="12" md="5" lg="4")
                      NavDrawer
                  
                  KeyExpressions(:subtitle="`Review these ${lesson.keyExpressions.length} key expressions from the transcript. You can press on each one to see the meaning and an example sentence.`")
                  
                  ImagePrompt(v-if="lesson.imagePrompts && lesson.imagePrompts.length > 0", subtitle="Your teacher will show you an image, ask you a question, and give you a time limit to answer.")
                  CheckUnderstanding(subtitle="Your teacher will check your understanding by asking you these questions:")
                  
                  
                  Hangman(title="Guessing Game", subtitle="Type any letters and try to guess the expression", note="Some expresssions might have punctuation, like question marks (?), exclamation marks (!), hyphens (-), or single quotation marks (').", :keyExpressions="lesson.keyExpressions")

                  MemoryGame(subtitle="Press on any expression card and match it to the correct meaning card.")
                  
                  DictationGame(subtitle="First press 'play' to listen to a key phrase from this lesson, then match it to the correct meaning from the dropdown list.")
                  
                  Cloze(v-if="lesson.cloze && lesson.cloze.length > 0", subtitle="Whenever you see a red marked expression, try to replace it with one of the key phrases from this lesson.")
                  
                  
                  Shadowing(subtitle="Shadowing is a great way to improve your natural pronunciation and intonation. Watch this part of the episode, press any unknown expressions, and try to mimic the exact speaking style of the characters.")

                  Discussion(subtitle="Your teacher will ask you the following discussion questions. Try to dive in and answer in detail, never with a simple 'yes' or 'no'.")
                  
                  TensePractice(v-if="lesson.tensePractice && lesson.tensePractice.past && lesson.tensePractice.past.length > 0", subtitle="Decide on a tense and click on it. You will have to fill in the plot summary in the tense of your choice.")

                  ArticlesPractice(subtitle="Click on any incorrect articles, or click in the space before a word that needs an article.", note="Some articles may be correct already, but clicking on them will show an alternate option.")

                  Summary(panelHeader="Press here to see the plot summary, only AFTER checking your understanding")

</template>

<script>
import NavDrawer from "@/components/Lesson/NavDrawer.vue";

import Title from "@/components/Lesson/Sections/Title.vue";
import Summary from "@/components/Lesson/Sections/Summary.vue";
import WarmUp from "@/components/Lesson/Sections/WarmUp.vue";
import KeyExpressions from "@/components/Lesson/Sections/KeyExpressions/KeyExpressions.vue";
import CheckUnderstanding from "@/components/Lesson/Sections/CheckUnderstanding.vue";
import ImagePrompt from "@/components/Lesson/Sections/ImagePrompt/ImagePrompt.vue";
import Discussion from "@/components/Lesson/Sections/Discussion.vue";
import Shadowing from "@/components/Lesson/Sections/Shadowing/Shadowing.vue";
import RolePlay from "@/components/Lesson/Sections/RolePlay.vue";
import Cloze from "@/components/Lesson/Sections/Cloze.vue";
import MemoryGame from "@/components/Games/Memory.vue";
import DictationGame from "@/components/Games/Dictation.vue";
import Hangman from "@/components/Games/Hangman/Hangman.vue";
import ArticlesPractice from "@/components/Lesson/Sections/ArticlesPractice/ArticlesPractice.vue";
import TensePractice from "@/components/Lesson/Sections/TensePractice/TensePractice.vue";

import { get, sync, call } from "vuex-pathify";
export default {
  name: "Lesson",
  components: {
    Title,
    NavDrawer,
    Summary,
    WarmUp,
    KeyExpressions,
    CheckUnderstanding,
    ImagePrompt,
    Discussion,
    Shadowing,
    RolePlay,
    Cloze,
    MemoryGame,
    DictationGame,
    Hangman,
    ArticlesPractice,
    TensePractice,
  },
  data() {
    return { loading: true, error: null };
  },
  created() {
    this.init();
  },
  computed: {
    ...get({ lesson: "Lessons/lesson" }),
    ...sync({ isTeacher: "Lessons/isTeacher" }),
  },
  methods: {
    ...call({
      clearLesson: "Lessons/clearLesson",
      updateLesson: "Lessons/updateLesson",
      getLessonIdFromExpiringLesson: "Lessons/getLessonIdFromExpiringLesson",
    }),
    async init() {
      try {
        this.loading = true;
        this.isTeacher = false;

        await this.clearLesson();

        const lessonId = await this.getLessonIdFromExpiringLesson({
          id: this.$route.params.id,
        });

        console.log({ lessonId });
        await this.updateLesson({ id: lessonId });
      } catch (err) {
        console.error("Lesson init error:", err);
        this.error = "NOT_FOUND";
      } finally {
        setTimeout(() => {
          this.loading = false;
        }, 100);
      }
    },
  },
};
</script>

<style lang="scss">
ol,
ul {
  margin-top: 1rem;
}
li {
  margin-bottom: 1rem;
}
</style>
