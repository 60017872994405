import { render, staticRenderFns } from "./Hangman.vue?vue&type=template&id=0e912572&scoped=true&lang=pug&"
import script from "./Hangman.vue?vue&type=script&lang=js&"
export * from "./Hangman.vue?vue&type=script&lang=js&"
import style0 from "./Hangman.vue?vue&type=style&index=0&id=0e912572&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e912572",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VAlert,VBtn,VCard,VCol,VExpandTransition,VFadeTransition,VIcon,VRow,VSkeletonLoader})
