<template lang="pug">
    v-card.pa-5.mt-md-n3(outlined tile color="secondary")
      v-row(align="center")
          v-col(cols="12" md="3" v-if="expanded && $vuetify.breakpoint.mdAndUp")
              v-img(src="@/assets/images/warning.svg" contain max-height="300px" aspect-ratio="1").mx-auto
          v-col(cols="12" :md="expanded ? 9 : 12")
              h2.mb-5.warning--text.text-center(v-if="!expanded") Warning: this is the teacher's guide - it is NOT for students.
              
              template(v-if="!studentVersionLink")
                v-fade-transition
                  .expanded(v-if="expanded")
                    p You should send to your students a link to the student's version of this lesson.
                    p The student's version does NOT include teaching tips and guides that are meant for your eyes only.
                    p Most importantly, the student's version does NOT include the answers to exercises.
                    p Click below to get a link to the student's version.
                    v-btn( color="primary" depressed large @click="handleClickCreateStudentVersion" :loading="loading").font-weight-bold
                      v-icon.mr-2 mdi-link-variant
                      | Student Version
                  template(v-else)
                    v-btn(block depressed :x-large="$vuetify.breakpoint.mdAndUp" color="warning" @click="expanded = true")
                      v-icon mdi-chevron-down
                      span.mx-2.font-weight-bold Get student's version
                      v-icon mdi-chevron-down

              template(v-if="studentVersionLink")
                  v-expand-transition
                      .link-area(v-if="copied")
                          h3.mb-2 Copied! Now email or text it to your students, BEFORE you start your lesson.
                          .yellow.lighten-4.pa-4.max-width-box
                              a(:href="studentVersionLink", target="_blank") {{studentVersionLink}}
                          p.mt-2 Note: this lesson link will stop working after about a week. You can always generate a new one.
                      .link-area(v-else)
                        h2.mb-5.primary--text Next: Copy this link...
                        LinkCopy(:link="studentVersionLink" @done="copied=true")
</template>

<script>
import { get, sync, call } from "vuex-pathify";
import LinkCopy from "@/components/LinkCopy.vue";
export default {
  name: "CreateStudentVersion",
  components: { LinkCopy },
  data() {
    return {
      expanded: false,
      studentVersionLink: null,
      cachedStudentVersionLink: null,
      copied: false,
      loading: false,
    };
  },
  computed: {
    ...get({ hostname: "hostname" }),
    ...sync({ copyProtection: "copyProtection" }),
  },
  mounted() {
    this.init();
  },
  methods: {
    ...call({ createStudentVersion: "Lessons/createStudentVersion" }),
    async init() {
      this.cachedStudentVersionLink = await this.createStudentVersion();
    },
    async handleClickCreateStudentVersion() {
      try {
        this.loading = true;

        const studentVersion =
          this.cachedStudentVersionLink || (await this.createStudentVersion());

        this.studentVersionLink =
          this.hostname + "/students/lesson/" + studentVersion;
      } catch (err) {
        console.error(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.max-width-box {
  display: inline-block;
  max-width: 70vw;
}
</style>
