<template lang="pug">
    v-card.letter-input(:color="calcColor", flat max-width="500").mx-auto.my-12.pa-5.text-center
        input.text#hangman-letter-input(autocomplete='off', autocorrect="off", spellcheck="false", outlined x-large hide-details, :placeholder="$vuetify.breakpoint.smAndDown ? 'Type here' : 'Type letters here'", @focus="inputIsActive = true", @blur="inputIsActive = false", @keypress="handleInput")
</template>

<script>
export default {
  name: "LetterInput",
  props: {
    expression: { type: String, required: true },
  },
  data() {
    return {
      input: null,
      inputIsActive: false,
    };
  },
  computed: {
    calcColor() {
      return this.inputIsActive ? "success lighten-4" : "yellow lighten-3";
    },
  },
  methods: {
    handleInput(e) {
      this.$emit("input", e.key);
    },
  },
};
</script>

<style lang="scss">
.game-board {
  .letter-input input.text {
    font-family: monospace !important;
    font-size: 2rem;
    text-align: center;
    color: black;
    width: 100%;
  }
}

@media only screen and (min-width: 1200px) {
  .game-board {
    .letter-input input.text {
      padding: 1rem;
    }
  }
}
</style>
