<template lang="pug">
  .countdown(:class="[backgroundColor, {'white--text': backgroundColor}]")
    .d-flex.align-center.justify-center.mx-3(v-if="currentTime && (currentTime.minutes || currentTime.seconds)")
      v-btn.reset(:dark="!!backgroundColor" text @click="$emit('reset')") Restart
      v-spacer
      .label.minutes.d-flex.align-end.mr-3(v-if="currentTime && currentTime.minutes")
        .number.text-h2.mr-2.font-weight-bold {{currentTime.minutes}}
        .caption.text-h6 m
      .label.d-flex.align-end
        .number.text-h2.mr-2.font-weight-bold {{currentTime.seconds}}
        .caption.text-h6 s

    .times(v-else)
      .d-flex.align-center.justify-center.flex-md-row
        .text-h2.invisible(v-if="$vuetify.breakpoint.mdAndUp") pl
        v-subheader.text--disabled Countdown
        v-btn(v-for="time in times", text :dark="!!backgroundColor" :key="time.type + time.value", @click="time.type === 's' ? emit(time) : emit(time)") {{time.value}} {{time.type}}
        v-btn(v-if="showRestart", text :color="backgroundColor ? yellow : 'accent'" @click="$emit('reset')").font-weight-bold
          v-icon.mr-2 mdi-undo
          | Restart
</template>

<script>
export default {
  name: "Countdown",
  props: {
    currentTime: { type: Object, default: null },
    bgColor: { type: String, default: "yellow darken-4" },
    transparent: { type: Boolean, default: false },
    showRestart: { type: Boolean, default: false },
  },
  data() {
    return {
      times: [
        { type: "s", value: 5 },
        { type: "s", value: 30 },
        { type: "m", value: 1 },
        { type: "m", value: 2 },
        { type: "m", value: 3 },
        { type: "m", value: 5 },
        { type: "m", value: 10 },
        { type: "m", value: 15 },
        { type: "m", value: 20 },
        { type: "m", value: 30 },
      ],
    };
  },
  created() {
    if (this.$vuetify.breakpoint.smAndDown)
      this.times = [
        { type: "s", value: 15 },
        { type: "s", value: 30 },
        { type: "m", value: 1 },
      ];
  },
  computed: {
    backgroundColor() {
      if (
        this.currentTime &&
        (this.currentTime.minutes || this.currentTime.seconds)
      )
        return "red";
      else if (this.transparent) return null;
      else return "yellow darken-4";
    },
  },
  methods: {
    emit(val) {
      this.$emit("start", val);
    },
  },
};
</script>

<style lang="scss" scoped>
.invisible {
  visibility: hidden;
}
</style>
