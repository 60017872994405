<template lang="pug">
  .note.px-md-2.text-center.font-weight-bold(:style="{'background-color': backgroundColor, color: foregroundColor}", v-if="lesson.active !== false")
    | {{calcLessonHours(lesson)}} {{$vuetify.breakpoint.mdAndUp ? 'class' : ''}} hours in this lesson!
    
    v-tooltip(top)
      template(v-slot:activator="{ on, attrs }")
          a.text-decoration-none(v-on="on", href="#" @click.prevent).ml-md-1
            v-icon(color="white" small) mdi-information-variant
      span This lesson contains {{calcLessonHours(lesson)}} lesson hours <br>for an average low-intermediate (low-B1) student

</template>

<script>
export default {
  name: "LessonTitleImageOverlay",
  props: {
    lesson: { type: Object, default: null },
    backgroundColor: { type: String, default: null },
    foregroundColor: { type: String, default: null },
  },
  computed: {},
  methods: {
    calcLessonHours(lesson) {
      if (lesson.keyExpressions.length < 10) return "3+";
      else if (
        (lesson.cloze && lesson.cloze.length > 0) ||
        lesson.keyExpressions.length > 28
      ) {
        return "5+";
      } else {
        return "4+";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.note {
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
}
</style>
