<template lang="pug">
    Section(anchor="articlesPractice", title="Articles (a/an/the) Practice", :subtitle="subtitle", :note="note", :time="time")
        #exercise.exercise(v-if="showQuestion >= 0").mt-6
          keep-alive
            ClickTheDiff.mb-1(v-if="questions && questions.length > 0", :incorrect="questions[showQuestion].incorrect", :correct="questions[showQuestion].correct", :key="currentTry + '-' + questions[showQuestion].incorrect", offerTryMore :offerGoBack="showQuestion > 0", :offerTryAnother="showQuestion < questions.length - 1", :done="showQuestion >= questions.length -1" @loadMore="handleLoadMore" @restart="handleRestart" @doGoBack="handleGoBack")
</template>

<script>
import { get } from "vuex-pathify";
import { waitForElemToExist, scrollToTarget } from "@/helpers";
import Section from "@/components/Lesson/Section.vue";
import ClickTheDiff from "@/components/Tools/ClickTheDiff.vue";
export default {
  name: "ArticlesPractice",
  components: { Section, ClickTheDiff },
  props: {
    subtitle: { type: String, default: null },
    note: { type: String, default: null },
    time: { type: String, default: null },
  },
  data() {
    return {
      showQuestion: 0,
      currentTry: 0,
    };
  },

  computed: {
    ...get({ isTeacher: "Lessons/isTeacher", lesson: "Lessons/lesson" }),
    questions() {
      return this.lesson &&
        this.lesson.articlesPractice &&
        this.lesson.articlesPractice.length > 0
        ? this.lesson.articlesPractice
        : [];
    },
  },
  methods: {
    async handleLoadMore() {
      this.showQuestion++;

      if (this.$vuetify.breakpoint.smAndDown) {
        await waitForElemToExist("#exercise");
        const target = document.getElementById("exercise");
        scrollToTarget(target);
      }
    },
    handleGoBack() {
      if (this.showQuestion > 0) {
        // this.currentTry--
        this.showQuestion--;
        // scrollToTarget(document.getElementById("exercise"));
      }
    },
    handleRestart() {
      this.currentTry++;
      this.showQuestion = 0;

      if (this.$vuetify.breakpoint.smAndDown) {
        scrollToTarget(document.getElementById("exercise"));
      }
    },
  },
};
</script>
