<template lang="pug">
  #matching
    Section(:title="`Matching Game ${iteration}`", :subtitle="subtitle", :time="time")
        template(v-if="isTeacher")
            p If your student wins or resets the game, they will see the title of this game change, for example from <em>Matching Game {{iteration}}</em> to <em>Matching Game {{iteration + 1}}</em>.
            p That way you can also <a href="#" class="font-weight-bold" @click.prevent="init">start a new game</a> and follow along with your student, with your cards in the same positions as theirs.

        v-container.matching-game
            template(v-if="finished")
                v-card(color="success" flat).white--text.pa-6.text-center.mb-12
                    v-icon(x-large color="white") mdi-check-circle
                    h1.mb-3 Nice job!
                    v-btn(depressed @click="init") Play again?
            template(v-else)
                v-row(justify="center" align="center")
                    v-col(cols="6" md="3" v-for="card, idx in cards", :key="idx")
                        v-card.d-flex.flex-column.justify-center.align-center.py-1.p-relative(:disabled="matchedCards.includes(idx)" :class="{selected: selectedCards.includes(idx), matched: matchedCards.includes(idx), 'not-recommended': activeCardType === card.face && !selectedCards.includes(idx)}", height="120px" @click="tryCard(idx)", :flat="matchedCards.includes(idx)" :color="calcCardColor(card, idx)")
                            .d-inline-block.text-right.card-num(v-if="matchedCards.includes(idx)", :style="{width: '100%'}")
                                v-icon(v-if="matchedCards.includes(idx)").mb-2 mdi-check-circle
                            v-card.card-face.text-center.pa-1.pa-md-4.d-flex.align-center.justify-center(height="80px" flat color="transparent" :dark="!matchedCards.includes(idx)" :style="{overflow: 'hidden'}")
                              span {{card[card.face]}}
                            //- v-card.card-face.text-center.pa-1.pa-md-4.d-flex.align-center.justify-center(height="80px" flat color="transparent" :dark="!matchedCards.includes(idx)" :style="{overflow: 'hidden'}")
                            //-     template(v-if="selectedCards.includes(idx)")
                            //-         span.mb-0(:style="{'vertical-align':'top', 'font-size':'1.1rem', 'line-height': '1.2'}") {{mostRecentlySelectedCard}}
                            //-     template(v-else-if="matchedCards.includes(idx)")
                            //-         small.pa-1.pa-md-3 {{card.face === "front" ? card.front : card.back}}
                            //-     template(v-else)
                            //-         .font-weight-bold(large)  {{card.face === 'front' ? 'Expression' : 'Meaning'}}

            .reset.text-center.mt-6
                a(href="#" @click.prevent='init').font-weight-bold.text-decoration-none.pink--text You're on game {{iteration}}. Start a new one (game {{iteration + 1}})?

</template>

<script>
import Section from "@/components/Lesson/Section.vue";

import { get } from "vuex-pathify";
export default {
  name: "MatchingGame",
  components: { Section },
  props: {
    subtitle: { type: String, default: null },
    time: { type: String, default: null },
  },
  watch: {
    keyExpressions(newVal) {
      this.expressions = newVal;
    },
  },

  data() {
    return {
      maxExpressionsForGame: 8,
      selectedCards: [],
      matchedCards: [],
      activeCardType: null,
      expressions: [],
      iteration: 0,
    };
  },
  created() {
    this.init();
    // this.expressions = this.keyExpressions
    //   .map((expression) => expression) //to avoid mutating keyExpressions
    //   .filter((expression) => expression.meaning.split(" ").length < 8); // long meanings don't fit in boxes

    if (this.$vuetify.breakpoint.lgAndUp) {
      this.maxExpressionsForGame = 8;
    } else if (this.$vuetify.breakpoint.mdAndUp) {
      this.maxExpressionsForGame = 6;
    } else {
      this.maxExpressionsForGame = 4;
    }
  },
  computed: {
    ...get({ lesson: "Lessons/lesson", isTeacher: "Lessons/isTeacher" }),
    finished() {
      return this.matches.length === this.cards.length / 2;
    },
    cards() {
      // Step 1: shuffle key expressions
      // Step 2: slice out n of them for cards
      // Step 3: create double that number of cards (some with expressions, some with meanings)
      // Step 4: shuffle for the game board

      const shuffled = (array, seed) => {
        function random(seed) {
          var x = Math.sin(seed++) * 10000;
          return x - Math.floor(x);
        }

        var m = array.length,
          t,
          i;

        // While there remain elements to shuffle…
        while (m) {
          // Pick a remaining element…
          i = Math.floor(random(seed) * m--); // <-- MODIFIED LINE

          // And swap it with the current element.
          t = array[m];
          array[m] = array[i];
          array[i] = t;
          ++seed; // <-- ADDED LINE
        }

        return array;
      };

      const expressions = shuffled(this.expressions, this.iteration);
      const subsetOfExpressions = expressions.slice(
        0,
        this.maxExpressionsForGame
      );

      const cards = [];

      subsetOfExpressions.forEach((expression) => {
        cards.push({
          front: expression.expression,
          back: expression.meaning,
          face: "front",
        });
        cards.push({
          front: expression.expression,
          back: expression.meaning,
          face: "back",
        });
      });

      return shuffled(cards, this.iteration);
    },
    mostRecentlySelectedCard() {
      if (this.selectedCards.length > 0) {
        const lastCardAmongSelected = this.selectedCards[
          this.selectedCards.length - 1
        ];
        const mostRecentlySelectedCard = this.cards[lastCardAmongSelected];
        return mostRecentlySelectedCard[mostRecentlySelectedCard.face];
      } else {
        return null;
      }
    },
    matches() {
      const withoutDuplicates = this.matchedCards.filter(
        (itm, idx) => idx % 2 === 0
      );
      const reversed = withoutDuplicates.reverse();
      return reversed;
    },
  },
  methods: {
    init() {
      this.iteration++;
      this.selectedCards = [];
      this.matchedCards = [];

      this.activeCardType = null;
      this.expressions = this.lesson.keyExpressions
        .map((expression) => expression)
        .filter((expression) => expression.meaning.split(" ").length < 8); //re-init calculation by re-writing this variable
    },
    calcCardColor(card, idx) {
      if (this.selectedCards.includes(idx)) {
        return card.face === "front" ? "accent darken-4" : "pink darken-4";
      } else if (card.face === this.activeCardType) {
        return card.face === "front" ? "accent darken-1" : "pink darken-1";
      } else
        return card.face === "front" ? "accent lighten-1" : "pink lighten-3";
    },
    tryCard(n) {
      const cardNowClicked = this.cards[n];
      const cardPreviouslyClicked = this.cards[this.selectedCards[0]];

      this.activeCardType = cardNowClicked.face;

      if (this.matchedCards.includes(n)) return;

      if (this.selectedCards.length === 0) {
        this.selectedCards.push(n);
      } else if (this.selectedCards.length === 1) {
        if (n === this.selectedCards[0]) {
          this.selectedCards.pop();
          this.activeCardType = null;
        } else {
          // check for match

          if (cardNowClicked.front === cardPreviouslyClicked.front) {
            // match
            // window.debug("MATCH!");
            this.matchedCards.push(n, this.selectedCards[0]);
            this.selectedCards = [];
            this.activeCardType = null;
          } else {
            // no match
            // window.debug("NO MATCH!");
            this.selectedCards = [n];
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$neutralColor: #f5f5f5;

.selected {
  background-color: pink;
  color: white;
}

.matched {
  color: black;
}

.not-recommended {
  opacity: 0.2;
}

.card-num {
  position: absolute;
  top: 5px;
  right: 5px;
}

::v-deep .theme--light.v-card.matched {
  background-color: $neutralColor !important;
}

::v-deep .v-card--link:before {
  background-color: $neutralColor !important;
}

::v-deep .v-card--disabled {
  opacity: 0.7;
}
</style>
