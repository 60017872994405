<template lang="pug">
    Section(anchor="tensePractice", title="Grammar Tense Practice", :subtitle="subtitle", :note="note", :time="time")
        .text-center
            v-btn-toggle
                v-btn(v-if="lesson.tensePractice[tense.toLowerCase()]", x-large ,v-for="tense in tenses", :key="tense", :color="tense.toLowerCase() === activeTense ? 'primary' : null" , :class="{'white--text': tense.toLowerCase() === activeTense}", @click="activeTense = tense.toLowerCase()") {{tense}}

        
        .lines.my-6(v-if="activeTense")
            v-card.title.line(flat).mx-auto.mx-md-6
                v-row.my-6
                  v-col(cols="12" md="6", v-for="line, idx in lesson.tensePractice[activeTense]", :key="line + activeTense", :class="{'grey lighten-3': idx % 2 === 0, 'grey lighten-4': idx % 2 > 0}")
                    v-row(dense)
                      v-col(cols="2" md="2").text-center
                        .display-2 {{idx+1}}
                      v-col(cols="10" md="10")
                        .prompt.font-weight-bold.mb-3 {{line}}...
                        v-row.sequencer(v-for="n in 2", :key="`sequencer-${n}`", dense).my-6
                            v-col(cols="5" md="4") {{randomSequencers[idx + n]}}...
                            v-col(cols="7" md="8") _____________
                


</template>

<script>
import { get } from "vuex-pathify";
import Section from "@/components/Lesson/Section.vue";
import { randomFromArray } from "@/helpers.js";
export default {
  name: "TensePractice",
  components: { Section },
  props: {
    subtitle: { type: String, default: null },
    note: { type: String, default: null },
    time: { type: String, default: null },
  },
  data() {
    return {
      tenses: ["Past", "Present", "Future"],
      activeTense: "present",
      latestSequencer: null,
      sequencers: ["and then", "after that", "next", "following that"],
      randomSequencers: [],
    };
  },
  created() {
    for (let i = 0; i < 100; i++) {
      let next;
      let previous = this.randomSequencers[this.randomSequencers.length - 1];
      do {
        next = randomFromArray(this.sequencers).pop();
      } while (previous === next);

      this.randomSequencers.push(next);
    }
  },
  computed: {
    ...get({ lesson: "Lessons/lesson" }),

    lastLine() {
      const lastLine = this.lesson.summary.text[
        this.lesson.summary.text.length - 1
      ];
      const split = lastLine.split(". ");
      return split[split.length - 1];
    },
  },
  methods: {},
};
</script>
