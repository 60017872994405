<template lang="pug">
    .want-more.primary
      v-card.pa-4.pa-md-12(dark color="primary")
        v-row.flex-column-reverse.flex-md-row
          v-col(cols="12" md="4")
            .pa-7.pt-md-0
              router-link(to="/lessons")
                v-img(src="@/assets/logo_white_bg.png" contain max-width="280px")
              p(v-if="$vuetify.breakpoint.mdAndUp").mt-3 Hundreds of hours of TV Show lesson content made by certified native teachers
          v-col(cols="12" md="8")
            h1.mb-5 Want more {{lesson.show}} lesson material?
            p We've prepared hours of {{lesson.show}} episodes, as well as other shows, like: 
              em.shows {{otherShows}}, and more!
            v-btn(:to="`/lessons/${lesson.show.toLowerCase().split(' ').join('_')}`", x-large color="accent", :block="$vuetify.breakpoint.smAndDown")
              v-icon.mr-2 mdi-television-play
              | Check out more shows
</template>

<script>
import { get } from "vuex-pathify";
export default {
  name: "WantMore",

  computed: {
    ...get({ lesson: "Lessons/lesson", shows: "Lessons/shows" }),
    otherShows() {
      return this.shows
        .filter((show) => show.title !== this.lesson.show)
        .map((show) => show.title)
        .join(", ");
    },
  },
};
</script>
