import { render, staticRenderFns } from "./LessonTitleImageOverlay.vue?vue&type=template&id=24b2f166&scoped=true&lang=pug&"
import script from "./LessonTitleImageOverlay.vue?vue&type=script&lang=js&"
export * from "./LessonTitleImageOverlay.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24b2f166",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VImg } from 'vuetify/lib/components/VImg';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
installComponents(component, {VImg,VOverlay})
