<template lang="pug">
    Section(anchor="hangman", :title="title", :subtitle="subtitle", :time="time", :note="note")
      template(v-if="loading")
        v-skeleton-loader(type="card")
      
      template(v-else-if="!shuffled || shuffled.length < 1")
        v-alert(type="info" color="primary" outlined border="left") Start by letting us know which expressions to display

      template(v-else)
            .d-flex.justify-center.mt-md-12
                .meaning.d-flex.justify-md-center.align-center
                    .value.oblique.text-center “{{currentTerm.meaning}}”
            
            .d-flex.justify-center(v-if="currentTerm && currentTerm.expression")
                v-card#words.words.d-flex.justify-center.justify-md-space-between.align-center(flat ref="words", :class="{'flex-wrap': $vuetify.breakpoint.smAndDown}")
                    .word(v-for="word, idx in currentTerm.expression.split(' ')", :key="word + idx", :class="{'has-margin': idx < currentTerm.expression.split(' ').length - 1}")
                        
                        .letters.mx-auto.mx-md-1.d-inline
                            a.letter.d-inline.text-decoration-none(v-for="char, idx in word", :key="char+idx", :class="{correct: isCorrect(char), large: $vuetify.breakpoint.mdAndUp}", href="#", @click.prevent="focusOnTextInput")
                                | {{isCorrect(char) ? char.toUpperCase() : "?"}}
            
            .d-flex.justify-center.mt-5(v-if="numMissingLetters > 0")
                small
                    em Guess {{numMissingLetters}} more letters
            
            v-expand-transition
                v-card(v-if="complete", outlined border="left" max-width="800px").my-12.mx-auto.pa-0
                    .d-flex.flex-column.justify-start.grey.lighten-3.pa-5(v-if="currentTerm.example")
                        b.mr-2.my-0.mb-3.mb-md-1 Example of "{{currentTerm.expression}}":
                        .example.display-md-1.d-flex
                          .quote.display-1.mr-2 “
                          | {{currentTerm.example}}
                    
                    .pa-5
                        v-row(align="center")
                            v-col(cols="12" md="6")
                                h2.display-md-2.mb-2
                                    v-icon(large).mr-2 mdi-check-circle
                                    | Nice job!
                                p.mb-0.ml-11(v-if="currentIndex < shuffled.length - 1") Ready to try another one?

                            v-col(cols="12" md="6", v-if="currentIndex < shuffled.length - 1")
                                v-btn(depressed, block color="accent", x-large @click="goToNextWord()") Next game
                                    v-icon.ml-2 mdi-refresh
            
            v-fade-transition
              .game-board(v-if="!complete")
                  LetterInput(:expression="currentTerm.expression", :key="currentIndex", @input="tryLetter")
                  .d-flex.justify-center.align-center.flex-column.mt-6
                      v-btn(v-if="currentIndex < shuffled.length - 1", color="grey lighten-3", depressed, x-large @click="goToNextWord()") Try next one
                        v-icon.ml-2 mdi-arrow-right
                      v-btn(v-if="showRestart" text color="primary" @click="$emit('restart')").mt-5 Restart
</template>

<script>
import Section from "@/components/Lesson/Section.vue";
import { randomFromArray, scrollToTarget } from "@/helpers.js";
import LetterInput from "@/components/Games/Hangman/LetterInput.vue";
export default {
  name: "Hangman",
  components: { Section, LetterInput },
  props: {
    keyExpressions: { type: Array, required: true },
    title: { type: String, default: null },
    subtitle: { type: String, default: null },
    note: { type: String, default: null },
    time: { type: String, default: null },
    loading: { type: Boolean, default: false },
    showRestart: { type: Boolean, default: false },
  },
  watch: {
    currentIndex() {
      this.$emit("change", this.currentTerm);
    },
    shuffled() {
      // console.log("shuffled changed and now current term", this.currentTerm);
      this.$emit("change", this.currentTerm);
    },
  },
  data() {
    return {
      guessedRight: [],
      guessedWrong: [],
      currentIndex: 0,
      alphabet: [
        "a",
        "b",
        "c",
        "d",
        "e",
        "f",
        "g",
        "h",
        "i",
        "j",
        "k",
        "l",
        "m",
        "n",
        "o",
        "p",
        "q",
        "r",
        "s",
        "t",
        "u",
        "v",
        "w",
        "x",
        "y",
        "z",
        "-",
        ".",
        "?",
        "'",
      ],
    };
  },
  mounted() {},
  computed: {
    shuffled() {
      if (!this.keyExpressions || this.keyExpressions.length < 1) return [];

      // curly apostrophe fix
      this.keyExpressions.forEach((itm) => itm.expression.split("’").join("'"));

      const clean = this.keyExpressions.filter(
        (keyExpression) =>
          keyExpression.expression.length < 20 &&
          !keyExpression.expression.includes("!") &&
          !keyExpression.expression.includes("/") &&
          !keyExpression.expression.includes("[") &&
          !keyExpression.expression.includes("]") &&
          !keyExpression.expression.includes(",")
      );

      const shuffled = randomFromArray(clean, clean.length);

      return shuffled;
    },
    currentTerm() {
      return this.shuffled && this.shuffled.length > 0
        ? this.shuffled[this.currentIndex]
        : "";
    },
    guessedLetters() {
      return [...this.guessedRight, ...this.guessedWrong];
    },
    numMissingLetters() {
      let remainder =
        this.currentTerm && this.currentTerm.expression
          ? this.currentTerm.expression.toLowerCase().split(" ").join("")
          : "unknown";

      // const targetLetters = this.currentTerm.expression.split(" ").join("").length
      this.guessedRight.forEach((letter) => {
        remainder = remainder.split(letter).join("");
      });

      return remainder.length;
    },
    complete() {
      return this.numMissingLetters < 1;
    },
  },
  methods: {
    tryLetter(letter) {
      const lc = this.currentTerm.expression.toLowerCase();

      if (lc.includes(letter.toLowerCase())) {
        this.guessedRight.push(letter.toLowerCase());
      } else {
        this.guessedWrong.push(letter.toLowerCase());
      }

      // this.$refs.words.$el.scrollIntoView();
    },
    wasGuessed(letter) {
      return this.guessedLetters.includes(letter.toLowerCase());
    },
    isCorrect(letter) {
      return this.guessedRight.includes(letter.toLowerCase());
    },
    isVowel(letter) {
      return ["a", "e", "i", "o", "u"].includes(letter.toLowerCase());
    },
    goToNextWord() {
      this.guessedWrong = [];
      this.guessedRight = [];
      this.currentIndex++;

      if (this.$vuetify.breakpoint.smAndDown)
        scrollToTarget(document.getElementById("hangman"), -350);

      this.$emit("reset");
    },
    getAlphabetLetterColor(letter) {
      if (this.isVowel(letter)) {
        return "grey lighten-2";
      } else {
        return null;
      }
    },
    focusOnTextInput() {
      const input = document.getElementById("hangman-letter-input");
      input.focus();
      scrollToTarget(input, 300);
    },
  },
};
</script>

<style lang="scss" scoped>
#hangman {
  .letter {
    font-size: 1.5rem;
    width: 30px;
    font-weight: bold;
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    background: rgba(0, 0, 0, 0.03);
    border-bottom: 5px solid black;
    font-family: monospace !important;
    text-align: center;
    color: rgba(0, 0, 0, 0.04);
  }

  .letter.large {
    font-size: 3rem;
  }
  .letter.correct {
    border-bottom: 5px solid #03b39b;
    text-shadow: 1px 1px 0px rgba(255, 255, 255, 0.75), 3px 3px white;
    color: black;
  }
  .word.has-margin {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .word.has-margin:first-child {
    margin-left: 0;
  }
  .word.has-margin:last-child {
    margin-right: 0;
  }

  .meaning .value {
    font-size: 2rem;
  }

  .alphabet.max-width {
    max-width: 75%;
  }

  input.hangman-letter {
    width: 32px;
    height: 3rem;

    font-weight: bold;
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    background: rgba(0, 0, 0, 0.03);
    border-bottom: 5px solid black;
    font-family: monospace !important;
    text-align: center;
    font-size: 3rem;
  }

  input.hangman-letter::placeholder {
    color: rgba(0, 0, 0, 0.04);
  }
}
</style>
