<template lang="pug">
  .display-image(v-if="imageUrl" :style="{'background-color': 'black'}").p-relative.pt-5
    v-card(flat :color="transparent || blur ? null : 'red'" @click="blur ? $emit('click') : $emit('reset')").my-0
      v-alert(type="error" v-if="error") Oops, are you sure this image address is correct?
        v-btn(color="accent", dark @click="$emit('reset')" ).ml-md-3  Try again
      v-img(ref="img", :src="imgSrc", color="red", background-color="red" lazy-src="/image_prompt_loader.jpg", contain max-height="60vh", :class="{'inner-blur': blur && !imgSrc.includes('image_prompt_loader')}" @change="error=null", @error="error=$event")
      .prompt.text-center.pa-5(:class="{'p-absolute': $vuetify.breakpoint.mdAndUp, width: promptWidth}")
        .prompt--text(:class="{blur}") {{prompt}}
</template>

<script>
export default {
  name: "DisplayImage",
  props: {
    imageUrl: { type: String, default: null },
    prompt: { type: String, default: null },
    blur: { type: Boolean, default: false },
    transparent: { type: Boolean, default: false },
    isAnimated: { type: Boolean, default: false },
  },
  data() {
    return { error: null, promptWidth: null };
  },
  computed: {
    imgSrc() {
      if (this.isAnimated)
        return this.blur ? "/image_prompt_loader.jpg" : this.imageUrl;
      else return this.imageUrl;
    },
  },
  watch: {
    imageUrl() {
      this.setPromptWidth();
    },
  },
  mounted() {
    this.setPromptWidth();
  },
  methods: {
    setPromptWidth() {
      const elem = document.querySelector(".display-image .v-image__image");
      const { width } = this.getBackgroundSize(elem);
      if (width && width.length > 0) this.promptWidth = width;
    },
    getBackgroundSize(elem) {
      if (!elem) return null;
      // This:
      //       * Gets elem computed styles:
      //             - CSS background-size
      //             - element's width and height
      //       * Extracts background URL
      var computedStyle = getComputedStyle(elem),
        image = new Image(),
        src = computedStyle.backgroundImage.replace(
          /url\((['"])?(.*?)\1\)/gi,
          "$2"
        ),
        cssSize = computedStyle.backgroundSize,
        elemW = parseInt(computedStyle.width.replace("px", ""), 10),
        elemH = parseInt(computedStyle.height.replace("px", ""), 10),
        elemDim = [elemW, elemH],
        computedDim = [],
        ratio;
      // Load the image with the extracted URL.
      // Should be in cache already.
      image.src = src;
      // Determine the 'ratio'
      ratio =
        image.width > image.height
          ? image.width / image.height
          : image.height / image.width;
      // Split background-size properties into array
      cssSize = cssSize.split(" ");
      // First property is width. It is always set to something.
      computedDim[0] = cssSize[0];
      // If height not set, set it to auto
      computedDim[1] = cssSize.length > 1 ? cssSize[1] : "auto";
      if (cssSize[0] === "cover") {
        // Width is greater than height
        if (elemDim[0] > elemDim[1]) {
          // Elem's ratio greater than or equal to img ratio
          if (elemDim[0] / elemDim[1] >= ratio) {
            computedDim[0] = elemDim[0];
            computedDim[1] = "auto";
          } else {
            computedDim[0] = "auto";
            computedDim[1] = elemDim[1];
          }
        } else {
          computedDim[0] = "auto";
          computedDim[1] = elemDim[1];
        }
      } else if (cssSize[0] === "contain") {
        // Width is less than height
        if (elemDim[0] < elemDim[1]) {
          computedDim[0] = elemDim[0];
          computedDim[1] = "auto";
        } else {
          // elem's ratio is greater than or equal to img ratio
          if (elemDim[0] / elemDim[1] >= ratio) {
            computedDim[0] = "auto";
            computedDim[1] = elemDim[1];
          } else {
            computedDim[1] = "auto";
            computedDim[0] = elemDim[0];
          }
        }
      } else {
        // If not 'cover' or 'contain', loop through the values
        for (var i = cssSize.length; i--; ) {
          // Check if values are in pixels or in percentage
          if (cssSize[i].indexOf("px") > -1) {
            // If in pixels, just remove the 'px' to get the value
            computedDim[i] = cssSize[i].replace("px", "");
          } else if (cssSize[i].indexOf("%") > -1) {
            // If percentage, get percentage of elem's dimension
            // and assign it to the computed dimension
            computedDim[i] = elemDim[i] * (cssSize[i].replace("%", "") / 100);
          }
        }
      }
      // If both values are set to auto, return image's
      // original width and height
      if (computedDim[0] === "auto" && computedDim[1] === "auto") {
        computedDim[0] = image.width;
        computedDim[1] = image.height;
      } else {
        // Depending on whether width or height is auto,
        // calculate the value in pixels of auto.
        // ratio in here is just getting proportions.
        ratio =
          computedDim[0] === "auto"
            ? image.height / computedDim[1]
            : image.width / computedDim[0];
        computedDim[0] =
          computedDim[0] === "auto" ? image.width / ratio : computedDim[0];
        computedDim[1] =
          computedDim[1] === "auto" ? image.height / ratio : computedDim[1];
      }
      // Finally, return an object with the width and height of the
      // background image.
      return {
        width: computedDim[0],
        height: computedDim[1],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.blur {
  filter: blur(8px);
}

.inner-blur {
  background-color: black;
}
::v-deep .inner-blur .v-image__image {
  filter: blur(15px);
}

h1 {
  filter: unset;
}

.display-image ::v-deep .v-image__image {
  background-color: black;
}

.prompt,
.click-prompt {
  position: relative;
  background: black;

  color: white;
  padding-left: 0.25rem;
  padding-right: 0.25rem;

  .prompt--text {
    font-size: 1.5rem;
    font-weight: bold;
  }
}

.prompt {
  width: 100%;
}

.has-shadow {
  box-shadow: 3px 3px 2px rgba(0, 0, 0, 0.25);
}

.click-prompt {
  z-index: 10;
  position: absolute;
  // top: 0;
  // right: 0;
}
</style>
