<template lang="pug">
    .chips(v-if="lesson && lesson.active !== false")
        .p-absolute.badge.badge-top-left(v-if="isNew(lesson.created)")
            v-chip.ma-2(color="transparent").white--text Fresh!
        .p-absolute.badge.badge-top-right(v-if="!isFreeEpisode")
            v-chip.ma-2.font-weight-bold(v-if="userData && userData.subscriptionIsActive", color="accent") You have access!
            v-chip.ma-2.font-weight-bold(v-else color="hot pink" dark) Subscription Only
        .p-absolute.badge.badge-top-right(v-else)
            v-chip.ma-2.font-weight-bold(color="success") Free
</template>

<script>
import { get } from "vuex-pathify";
export default {
  name: "LessonChips",
  props: {
    isFreeEpisode: { type: Boolean, default: false },
    lesson: { type: Object, default: null },
  },
  computed: {
    ...get({ userData: "User/userData" }),
  },
  methods: {
    isNew(created) {
      if (created) {
        const dt = new Date();
        dt.setDate(dt.getDate() - 7);
        return created.toDate() > dt;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.badge {
  z-index: 8;
  top: 0;
}
.badge-top-left {
  left: 0;
}
.badge-top-right {
  right: 0;
}

.low-opacity {
  opacity: 0.4;
}
</style>
