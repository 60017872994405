<template lang="pug">
  #imagePrompt(v-if="lesson && lesson.imagePrompts && lesson.imagePrompts.length > 0")
    Section(title="Image Prompt", :subtitle="subtitle", :time="time", anchor="imagePrompts")
      v-row(v-if="imagePrompt.image" dense)
        v-col(cols="12")
          Countdown(transparent :currentTime="countdownTime", @start="handleStartTimer", @reset="handleReset") 
        v-col(cols="12")
          DisplayImage(transparent :prompt="imagePrompt.prompt", :blur="blur", :imageUrl="getImage(imagePrompt.image)", :isAnimated="imagePrompt.isAnimated" @reset="handleReset", @click="handleStartTimer({type: 's', value:20})")
        v-col(cols="12" v-if="lesson.imagePrompts.length > 1")
          v-row
            v-col(cols="6")
              v-btn(x-large text block :disabled="currentPromptId < 1", @click="handlePrevious")
                v-icon.mr-1 mdi-arrow-left
                | Prev
            v-col(cols="6")
              v-btn(x-large depressed color="accent" block :disabled="currentPromptId >= lesson.imagePrompts.length - 1", @click="handleNext")
                | Next
                v-icon.ml-1 mdi-arrow-right
          
</template>

<script>
import { get } from "vuex-pathify";
import Section from "@/components/Lesson/Section.vue";

import DisplayImage from "@/components/Tools/ImageTimer/DisplayImage.vue";
import Countdown from "@/components/Tools/ImageTimer/Countdown.vue";

import Timer from "easytimer.js";

export default {
  name: "WarmUp",
  components: { Section, DisplayImage, Countdown },
  props: {
    subtitle: { type: String, default: null },
    time: { type: String, default: null },
  },
  data() {
    return { countdownTime: null, blur: true, currentPromptId: 0 };
  },
  mounted() {},
  computed: {
    ...get({ lesson: "Lessons/lesson" }),
    imagePrompt() {
      return this.lesson.imagePrompts[this.currentPromptId];
    },
  },
  methods: {
    handleReset() {
      if (this.timer) {
        this.timer.stop();
        this.timer.removeEventListener("secondsUpdated");
      }

      this.countdownTime = null;
      this.timer = null;
      this.prompt = null;
    },
    handleStartTimer(time) {
      if (this.timer) {
        this.timer.stop();
        this.timer.removeEventListener("secondsUpdated");
      }

      this.countdownTime = {
        minutes: time.type === "m" ? time.value : 0,
        seconds: time.type === "s" ? time.value : 0,
      };

      this.blur = false;
      this.timer = new Timer();

      const seconds = time.type === "s" ? time.value : time.value * 60;
      this.timer.start({
        precision: "seconds",
        countdown: true,
        startValues: { seconds },
      });

      const vm = this;
      this.timer.addEventListener("started", function () {
        vm.blur = false;
      });

      this.timer.addEventListener("stopped", function () {
        vm.blur = true;
      });

      this.timer.addEventListener("secondsUpdated", function () {
        const val = {
          minutes:
            vm.timer && vm.timer.getTimeValues
              ? vm.timer.getTimeValues().minutes
              : 0,
          seconds:
            vm.timer && vm.timer.getTimeValues
              ? vm.timer.getTimeValues().seconds
              : 0,
        };
        vm.countdownTime = val;
      });
    },
    handlePrevious() {
      const prevId =
        this.currentPromptId - 1 > 0 ? this.currentPromptId - 1 : 0;
      this.handleReset();
      this.currentPromptId = prevId;
    },
    handleNext() {
      const nextId = this.currentPromptId + 1;
      this.handleReset();
      this.currentPromptId = nextId;
    },
    getImage(str) {
      const { show, season, episode } = this.lesson;
      const showAsFilename = show.split(" ").join("_").toLowerCase();
      const ep = ("" + episode).padStart(2, "0");
      return str.includes("giphy")
        ? str
        : `/screencaps/shows/${showAsFilename}/s0${season}_e${ep}/` + str;
    },
  },
};
</script>
