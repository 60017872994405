<template lang="pug">
    v-row(align="start" dense)
        v-col(cols="12" md="8")
            v-text-field#copy(readonly, hide-details, outlined, :background-color="copyProtection ? 'secondary' : 'success lighten-2'" placeholder="Copy this link" :value="shortLink")
        v-col(cols="12" md="4")
          v-btn(:color="button.color" block x-large depressed @click="handleCopy").font-weight-bold
              | {{button.text}}
              v-icon.ml-2 {{button.icon}}
        v-col(cols="12")
          p.mt-4 Click the 'Copy Link' button to copy this link and send it to your student(s)
        #hidden-text-copy-area(ref='hiddenTextCopyArea')
</template>

<script>
import { sync } from "vuex-pathify";
import { copyToClipboard } from "@/helpers.js";
const DEFAULT_BUTTON = {
  text: "Copy Link",
  color: "accent",
  icon: "mdi-content-copy",
};
export default {
  name: "LinkCopy",
  props: {
    link: { type: String, required: true },
  },
  data() {
    return {
      button: JSON.parse(JSON.stringify(DEFAULT_BUTTON)),
    };
  },
  computed: {
    ...sync({ copyProtection: "copyProtection" }),
    shortLink() {
      if (this.link.length > 90) {
        return this.link.substr(0, 90) + "...";
      } else return this.link;
    },
  },
  methods: {
    handleCopy() {
      this.button.text = "Copied!";
      this.button.color = "success";
      this.button.icon = "mdi-check-circle";

      this.copyProtection = false;

      copyToClipboard(this.link, this.$refs.hiddenTextCopyArea);

      setTimeout(() => {
        this.button = JSON.parse(JSON.stringify(DEFAULT_BUTTON));
      }, 1500);

      setTimeout(() => {
        this.copyProtection = true;
        this.$emit("done");
      }, 500);
    },
  },
};
</script>
