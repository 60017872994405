<template lang="pug">
  #shadowing
    Section(title="Shadowing Practice", :subtitle="subtitle", :time="time")
        v-card(v-if="isTeacher" flat).mb-4
          v-row.flex-column-reverse.flex-md-row
            v-col(cols="12" md="6")
              v-card(color="grey lighten-5", flat).pa-5.text--secondary
                b.mb-3 How to use this section
                p Ask your students to memorize this passage from the transcript and repeat it back to you, being careful to match the pronunciation, inflection, and emotion they heard in the show.
                p.mb-0 This can be assigned as homework or practiced together during class time. 
            v-col(cols="12" md="6")
              .px-12
                v-img(src="@/assets/images/shadowing.svg" cover)

        .exercises(v-for="exercise in lesson.shadowing", :key="exercise.time")
            v-card.grey.lighten-4.pa-5(flat max-width="700px")
                .header.d-flex.mb-3.align-center
                  v-icon(size="5rem" color="grey lighten-2").mb-3 mdi-comment-quote-outline
                  h4.yellow.lighten-3.d-inline-block.px-2 Start playback at time: {{exercise.time}}
                v-card.hint.pa-2(flat color="grey lighten-3").text--secondary
                  .d-flex.align-start
                    v-icon.mt-2 mdi-information
                    .value.ml-3 Where there are yellow-highlighted gaps in the passage,  press on the gap to see the correct text.
                
                .lines.mt-6
                  .line(v-for="q, shadowIdx in exercise.text", :key="q + shadowIdx").mb-4
                    .d-flex
                      v-chip( color="white" :style="{'min-width':'40px'}").justify-center {{shadowIdx + 1}}
                      ClozeLine(:value="q").ml-2
                    //- span.ml-3 {{q}}

</template>

<script>
import { get } from "vuex-pathify";
import Section from "@/components/Lesson/Section.vue";
import ClozeLine from "@/components/ClozeLine.vue";
export default {
  name: "Shadowing",
  components: { Section, ClozeLine },
  props: {
    subtitle: { type: String, default: null },
    time: { type: String, default: null },
  },
  computed: {
    ...get({ lesson: "Lessons/lesson", isTeacher: "Lessons/isTeacher" }),
  },
};
</script>
