<template lang="pug">
    .cloze-line
      //- pre elements {{elements}}
      .elements.d-inline(v-for="elem, idx in elements", :key="idx")
        span(v-if="elem.type === 'span'") {{elem.value}}
        .cloze.d-inline-block.mx-1(:ref="`elem-${idx}`", v-else-if="elem.type === 'cloze'", text, @click="handleClick(elem, idx)")
          span.answer(v-if="answers[idx]") {{answers[idx]}}
          span.question(v-else) {{elem.replacement ? elem.original : underscores(elem.replacement ? elem.replacement.length : elem.original.length)  }}
      
</template>

<script>
export default {
  name: "ClozeLine",
  props: {
    value: { type: String, required: true },
  },
  data() {
    return {
      elements: [],
      answers: {},
    };
  },
  created() {
    this.initElements();
  },
  computed: {},
  methods: {
    underscores(length) {
      return "_".repeat(length);
    },
    initElements() {
      if (!this.value) return "";
      // window.debug("\n\nparsing..", this.value);

      // eslint-disable-next-line no-useless-escape
      const re = /(\%\{.*?\})/g;

      const split = this.value.split(re).filter(Boolean);

      // window.debug("split", split);

      const elems = [];
      split.forEach((elem) => {
        if (elem.includes("{")) {
          const clean = elem.split("%{").join("").split("}").join("");
          const elemSplit = clean.split("|");

          const original = elemSplit[0] ? elemSplit[0].trim() : null;
          const replacement = elemSplit[1] ? elemSplit[1].trim() : null;

          elems.push({ type: "cloze", original, replacement });
        } else {
          elems.push({ type: "span", value: elem.trim() });
        }
      });
      this.elements = elems;
    },
    handleClick(elem, idx) {
      // window.debug("clicked elem", elem);
      this.$set(
        this.answers,
        idx,
        elem.replacement ? elem.replacement : elem.original
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.cloze {
  span {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    border-radius: 5px;
  }

  .question {
    cursor: pointer;
    background: lightyellow;
  }
  .answer {
    background: rgb(214, 255, 214);
  }
}
</style>
