<template lang="pug">
    v-card.lessons-filter.grey.lighten-5.pa-3(flat :shaped="$vuetify.breakpoint.mdAndUp")
        template(v-if="$vuetify.breakpoint.smAndDown")
          v-select.white--text(outlined :items="shows", v-model="selectedShow", background-color="yellow lighten-5", label="Select a TV Show", item-text="title" item-value="title", hide-details, :value="lessonsFilter", @change="handleClick")
        template(v-else)
          .shows.d-flex.align-center.flex-column.flex-md-row
              .text--disabled Select a show:
              .show(v-for="show in shows", :key="show.title")
                v-chip(:color="isActiveChip(show.title) ? 'primary' : 'grey lighten-3'", @click="handleClick(show.title)").pa-4.ma-2
                  v-avatar(left)
                    template(v-if="show.isKidFriendly")
                      v-icon(small, :color="isActiveChip(show.title) ? 'white': 'black'") mdi-human-male-child
                    template(v-else)
                      v-icon(small :color="isActiveChip(show.title) ? 'white' : 'black'", v-if="isActiveChip(show.title)") mdi-television-play
                      v-icon(small v-else :color="isActiveChip(show.title) ? 'white' : 'black'") mdi-television

                  .d-inline.text(:class="{'font-weight-bold': isActiveChip(show.title)}", :style="{'font-size': '0.9rem', color: isActiveChip(show.title) ? 'white' : 'black'}")
                    span {{show.title}}
                    sup(v-if="show.isKidFriendly").ml-1 Kids
                    sup(v-else-if="show.adultsOnly").ml-1 Adults
          
        #lessons-list(ref="lessonsList")
</template>

<script>
import { get, sync } from "vuex-pathify";
export default {
  name: "LessonsFilter",
  data() {
    return { selectedShow: null };
  },
  computed: {
    ...get({
      lessons: "Lessons/lessons",
      shows: "Lessons/shows",
    }),
    ...sync({ lessonsFilter: "Lessons/lessonsFilter" }),
    activeLessonTitle() {
      if (!this.shows || this.shows.length < 1 || !this.lessonsFilter)
        return "?";

      const foundShow = this.shows.find((show) =>
        this.pareDown(show.title).includes(this.pareDown(this.lessonsFilter))
      );

      return foundShow ? foundShow.title : null;
    },
  },
  watch: {
    $route(newVal) {
      console.log("route change...");
      if (newVal.params.id)
        this.lessonsFilter = newVal.params.id
          .toLowerCase()
          .split(" ")
          .join("_");

      this.selectedShow = this.lessonsFilter;
    },
  },
  created() {
    this.selectedShow = this.activeLessonTitle;
  },
  methods: {
    pareDown: (str) =>
      str
        .toLowerCase()
        .split(" ")
        .join("")
        .split("-")
        .join("")
        .split("_")
        .join("")
        .trim(),
    isActiveChip(showTitle) {
      return (
        this.lessonsFilter.toLowerCase().split(" ").join("_") ===
        showTitle.toLowerCase().split(" ").join("_")
      );
    },
    handleClick(showTitle) {
      console.log("handling click for show title", showTitle);
      if (!this.isActiveChip(showTitle)) {
        history.pushState(
          null,
          showTitle,
          `/lessons/${showTitle.toLowerCase().split(" ").join("_")}`
        );
        this.lessonsFilter = showTitle.toLowerCase().split(" ").join("_");
      }

      setTimeout(() => {
        if (this.$vuetify.breakpoint.mdAndDown)
          this.$refs.lessonsList.scrollIntoView();
      }, 500);
    },
  },
};
</script>
